import React, { FunctionComponent } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { MaturityRating } from '@amzn/monera-app-alexa-shared-libs';
import { Link, List } from '@amzn/monera-app-core-components';

import { alexaTheme } from '../../theme';
import { DynamicContent } from '../dynamic-content';
import { MicTimeout } from '../mic-timeout';
import { SkillDetailsProps } from './types';

export const SkillDetails: FunctionComponent<SkillDetailsProps> = (props) => {
  const {
    titleCopy,
    isMobile = false,
    dynamicContentProps,
    developerPrivacyPolicyCopyByLocale,
    developerPrivacyPolicyUrlByLocale,
    developerTermsOfUseCopyByLocale,
    developerTermsOfUseUrlByLocale,
    invocationNameCopy,
    invocationNameValue,
    maturityRatingProps,
    micTimeoutProps,
    videoSkillProps,
    smartHomeSkillProps,
    flashBriefingSkillProps,
    titleFontSize = alexaTheme.typography?.desktopH2?.fontSize,
    titleLineHeight = alexaTheme.typography?.desktopH2?.lineHeight,
    listPadding = '0 0 0px 18px',
    listMargin = '4px 4px 4px 4px',
    listLineHeight = alexaTheme.typography?.desktopBody?.lineHeight,
    listFontSize = alexaTheme.typography?.desktopBody?.fontSize
  } = props;
  const smartHomeSkillText = smartHomeSkillProps ? (
    <>
      <AlexaText inline fontSize={listFontSize} data-cy={'smart-home-skill-intro'}>
        {smartHomeSkillProps.smartHomeSkillIntro}&nbsp;
      </AlexaText>
      <Link
        openInNewTab
        href={smartHomeSkillProps.smartHomeSkillLearnMoreUrl}
        fontSize={listFontSize}
        data-cy={'smart-home-skill-learn-more'}
      >
        {smartHomeSkillProps.smartHomeSkillLearnMore + '.'}
      </Link>
    </>
  ) : null;
  const videoSkillText = videoSkillProps ? (
    <AlexaText data-cy="video-skill-detail" fontSize={listFontSize}>
      {videoSkillProps}
    </AlexaText>
  ) : null;
  const flashBriefingSkillText = flashBriefingSkillProps ? (
    <AlexaText data-cy="flash-briefing-skill-detail" fontSize={listFontSize}>
      {flashBriefingSkillProps}
    </AlexaText>
  ) : null;
  const skillRatingText =
    maturityRatingProps || dynamicContentProps || micTimeoutProps ? (
      <>
        {maturityRatingProps ? (
          <>
            <MaturityRating
              {...maturityRatingProps}
              inlineText
              isMobile={isMobile}
              maturityRatingText={`${maturityRatingProps.maturityRating}. `}
              fontSize={listFontSize}
              lineHeight={listLineHeight}
            />
          </>
        ) : null}
        {dynamicContentProps ? (
          <DynamicContent
            {...dynamicContentProps}
            inlineText
            isMobile={isMobile}
            fontSize={listFontSize}
            lineHeight={listLineHeight}
          />
        ) : null}
        {micTimeoutProps ? (
          <MicTimeout
            {...micTimeoutProps}
            inlineText
            isMobile={isMobile}
            micTimeoutCanExtend={` ${micTimeoutProps.micTimeoutCanExtend}`}
            fontSize={listFontSize}
            lineHeight={listLineHeight}
          />
        ) : null}
      </>
    ) : null;
  const invocationNameText = invocationNameCopy ? (
    <AlexaText data-cy="invocation-name-copy-detail" fontSize={listFontSize}>
      {invocationNameCopy}&nbsp;
      <AlexaText bold inline data-cy="invocation-name-value-detail" fontSize={listFontSize}>
        {invocationNameValue}
      </AlexaText>
    </AlexaText>
  ) : null;
  const developerPrivacyPolicyLinkList = Object.keys(developerPrivacyPolicyCopyByLocale).map((locale, i) => {
    return (
      <Link
        openInNewTab
        href={developerPrivacyPolicyUrlByLocale[locale] ?? ''}
        data-cy={`privacy-policy-link-detail-${i}`}
        key={`privacy-policy-link-detail-${i}`}
        fontSize={listFontSize}
      >
        {developerPrivacyPolicyCopyByLocale[locale]}
      </Link>
    );
  });
  const developerTermsOfUseLinkList = Object.keys(developerTermsOfUseCopyByLocale).map((locale, i) => {
    return (
      <Link
        openInNewTab
        href={developerTermsOfUseUrlByLocale[locale] ?? ''}
        data-cy={`terms-of-use-link-detail-${i}`}
        key={`terms-of-use-link-detail-${i}`}
        fontSize={listFontSize}
      >
        {developerTermsOfUseCopyByLocale[locale]}
      </Link>
    );
  });
  let listItems = [
    smartHomeSkillText,
    videoSkillText,
    flashBriefingSkillText,
    skillRatingText,
    invocationNameText,
    ...developerPrivacyPolicyLinkList,
    ...developerTermsOfUseLinkList
  ];
  listItems = listItems.filter((item) => item != null);

  return (
    <>
      <AlexaText
        bold
        fontSize={titleFontSize}
        lineHeight={titleLineHeight}
        aria-level={2}
        data-cy="skill-details-title"
      >
        {titleCopy}
      </AlexaText>
      <List items={listItems} padding={listPadding} margin={listMargin} lineHeight={listLineHeight} />
    </>
  );
};

export * from './types';
