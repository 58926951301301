import React, { FunctionComponent, PropsWithChildren } from 'react';

import { StoresContainerProps } from '@amzn/monera-app-core/lib/state/components/stores-container/types';

import { AlertBannerContainer } from '../../state/stores/free-skill-alert-banner-group';
import { initialAlertBannerState } from '../../state/stores/free-skill-alert-banner-group/state';

export const AlexaStoresContainer: FunctionComponent<PropsWithChildren<StoresContainerProps>> = (props) => {
  const { children } = props;
  return <AlertBannerContainer initialState={initialAlertBannerState}>{children}</AlertBannerContainer>;
};
