import React, { FunctionComponent } from 'react';

import { serverConsole } from '@amzn/monera-app-core/lib/utils/server';

import { AlertBannerState, useAlertBannerStore } from '../../state/stores/free-skill-alert-banner-group';
import { FreeSkillAlertBannerGroupProps, FreeSkillAlertBannerProps } from './types';

export const FreeSkillAlertBannerGroup: FunctionComponent<FreeSkillAlertBannerGroupProps> = (props) => {
  serverConsole.log('FreeSkillAlertBannerGroup props: ', props);
  const [alertBannerState, alertBannerStateActions] = useAlertBannerStore();
  alertBannerStateActions.update(props as AlertBannerState);
  const { alertMessages } = alertBannerState;

  const getFreeSkillAlertBanners = () => {
    return alertMessages
      ?.filter(
        (alertMessage) =>
          typeof alertMessage.alertText != 'undefined' && typeof alertMessage.alertBannerType != 'undefined'
      )
      .map((alertMessage: FreeSkillAlertBannerProps, i) => (
        <FreeSkillAlertBanner key={`AlertMessage${i}`} {...alertMessage} />
      ));
  };

  return alertMessages && alertMessages.length > 0 ? <div>{getFreeSkillAlertBanners()}</div> : null;
};

export const FreeSkillAlertBanner: FunctionComponent<FreeSkillAlertBannerProps> = (props) => {
  const { alertTitleText, alertText, alertBannerType } = props;

  return (
    <div className="a-section">
      <div className={`a-box a-alert a-spacing-small a-alert-${alertBannerType}`} aria-level={2}>
        <div className="a-box-inner a-alert-container">
          {alertTitleText && alertTitleText != '' && <h4 className="a-alert-heading">{alertTitleText}</h4>}
          <i className="a-icon a-icon-alert" />
          <div className="a-alert-content">{alertText}</div>
        </div>
      </div>
    </div>
  );
};

export * from './types';
