import React, { FunctionComponent } from 'react';

import { Media, MediaType } from '@amzn/monera-app-core-components/lib/components/media';

import { FreeSkillLogoProps } from './types';

export const FreeSkillLogo: FunctionComponent<FreeSkillLogoProps> = (props) => {
  const {
    imageUrl,
    altText,
    title,
    imageMargin: imageMargin,
    imageWidth: imageWidth,
    imageHeight: imageHeight,
    imageMinWidth
  } = props;

  return (
    <Media
      mediaSrcUrl={imageUrl}
      mediaSrcType={MediaType.Image}
      alt={altText}
      title={title}
      margin={imageMargin}
      width={imageWidth}
      height={imageHeight}
      minWidth={imageMinWidth}
      data-cy={'alexa-free-skill-logo'}
    />
  );
};

export * from './types';
