import {
  actionMutation,
  AuthHeadersEnum,
  HTTPHeaders,
  isError,
  mutateMoneraService,
  MutateMoneraServiceProps,
  serverConsole,
  ShoppingPortalHeadersEnum
} from '@amzn/monera-app-core';
import { emitLatencyMetrics, meterFatalsFromResponse } from '@amzn/monera-app-core/lib/utils/metric-helper';

import { DisableSkillRequest } from './types';

export const disableSkill = async (disableSkill: DisableSkillRequest) => {
  // TODO: Implement save/update permissions API call
  const {
    requestId,
    asin,
    tenant,
    marketplaceId,
    ubid,
    sessionId,
    csrfToken,
    customerId,
    deviceType,
    locale,
    defaultLocale,
    localizedProductDetails
  } = disableSkill;

  const disableMutationProps: MutateMoneraServiceProps = {
    gqlMutation: actionMutation,
    variables: {
      asin,
      tenant,
      actionType: 'disable',
      marketplaceId,
      data: JSON.stringify(localizedProductDetails),
      customerId
    },
    headers: {
      [ShoppingPortalHeadersEnum.MarketplaceId]: marketplaceId,
      [ShoppingPortalHeadersEnum.UBID]: ubid,
      [ShoppingPortalHeadersEnum.SessionId]: sessionId,
      [ShoppingPortalHeadersEnum.Cookie]: document.cookie,
      [AuthHeadersEnum.AntiCsrfToken]: csrfToken,
      [HTTPHeaders.RequestId]: requestId,
      deviceType,
      locale,
      defaultLocale,
      localizedProductDetails
    }
  };
  serverConsole.log('disable PROPS: ', disableMutationProps);

  // Disable skill mutation metrics
  const methodName = 'DisableSkillMutation';
  const metricName = 'DisableSkillMutation';
  const startTime = Date.now();

  const response = await mutateMoneraService<{ response: any }>(disableMutationProps).catch((error) =>
    serverConsole.log('error disabling: ', error)
  );

  const mutateMoneraServiceResponseTime = Date.now() - startTime;
  emitLatencyMetrics(methodName, metricName, mutateMoneraServiceResponseTime, requestId, tenant);

  // eslint-disable-next-line no-console
  serverConsole.log('DISABLE RESPONSE', response);

  meterFatalsFromResponse(response, { methodName: 'DisableSkill', metricName, tenant: 'ALEXA_FREE_SKILLS', requestId });
  if (response === undefined || isError(response)) {
    serverConsole.log('error disabling skill');
    return undefined;
  } else {
    return JSON.parse((response.data as any)?.action?.response);
  }
};

export * from './types';
