import { StoreActionApi } from 'react-sweet-state';

import { AlertBannerState } from '../../types';

const init = (state: AlertBannerState) => ({ setState }: StoreActionApi<AlertBannerState>) => {
  if (state) {
    setState(state);
  }
};

export default init;
