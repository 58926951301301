import React, { FunctionComponent, useRef, useState } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { Link } from '@amzn/monera-app-core-components';

import { alexaTheme, alexaTypography } from '../../theme';
import { DynamicContentModal } from '../dynamic-content-modal';
import { DynamicContentProps } from './types';

export const DynamicContent: FunctionComponent<DynamicContentProps> = (props) => {
  const {
    dynamicContentContains,
    dynamicContentLowerCase,
    dynamicContentWarning,
    dynamicContentModalButtonCopy,
    dynamicContentModalTitle,

    fontSize = alexaTheme.typography?.desktopBody?.fontSize,
    lineHeight = alexaTheme.typography?.desktopBody?.lineHeight,
    inlineText = false,
    isMobile = false
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const handleCloseModal = () => {
    setModalOpen(false);
    anchorRef?.current?.focus();
  };

  const handleOpenModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const defaultDynamicContentTextProps = isMobile ? alexaTypography.mobileBody : alexaTypography.desktopBody;

  const dynamicContentText = (
    <>
      <AlexaText {...defaultDynamicContentTextProps} inline color="#0F1111" data-cy="dynamic-content-contains">
        {dynamicContentContains}:&nbsp;
      </AlexaText>
      <Link
        ref={anchorRef}
        href="#"
        fontSize={fontSize}
        lineHeight={lineHeight}
        data-cy="dynamic-content-link"
        onClick={handleOpenModal}
      >
        {dynamicContentLowerCase}.
      </Link>
    </>
  );

  return (
    <span>
      {dynamicContentWarning ? (
        <>
          <DynamicContentModal
            isMobile={isMobile}
            close={handleCloseModal}
            isOpen={modalOpen}
            buttonCopy={dynamicContentModalButtonCopy ?? ''}
            description={dynamicContentWarning}
            title={dynamicContentModalTitle ?? ''}
            data-cy="skill-details-dynamic-content-modal"
          />
          {inlineText ? <span>{dynamicContentText}</span> : <div>{dynamicContentText}</div>}
        </>
      ) : null}
    </span>
  );
};

export * from './types';
