import React, { FunctionComponent, useRef, useState } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { Link } from '@amzn/monera-app-core-components';

import { alexaTheme } from '../../theme';
import { MicTimeoutModal } from '../mic-timeout-modal';
import { MicTimeoutProps } from './types';

export const MicTimeout: FunctionComponent<MicTimeoutProps> = (props) => {
  const {
    micTimeoutCanExtend,
    micTimeoutLowerCase,
    micTimeoutDescription,
    micTimeoutModalUnderstand,
    micTimeoutModalTitle,
    fontSize = alexaTheme.typography?.desktopSM?.fontSize,
    lineHeight = alexaTheme.typography?.desktopSM?.lineHeight,
    inlineText = false,
    isMobile = false
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    anchorRef?.current?.focus();
  };

  const handleOpenModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const micTimeoutText = (
    <>
      <AlexaText inline color="#0F1111" fontSize={fontSize} lineHeight={lineHeight} data-cy="mic-timeout-extends">
        {micTimeoutCanExtend}&nbsp;
      </AlexaText>
      <Link
        ref={anchorRef}
        href="#"
        fontSize={fontSize}
        lineHeight={lineHeight}
        data-cy="mic-timeout-link"
        onClick={handleOpenModal}
      >
        {micTimeoutLowerCase}.
      </Link>
    </>
  );

  return (
    <span>
      {micTimeoutDescription ? (
        <>
          <MicTimeoutModal
            isMobile={isMobile}
            close={handleCloseModal}
            isOpen={isModalOpen}
            buttonCopy={micTimeoutModalUnderstand ?? ''}
            description={micTimeoutDescription}
            title={micTimeoutModalTitle ?? ''}
            data-cy="skill-details-mic-timeout-modal"
          />
          {inlineText ? <span>{micTimeoutText}</span> : <div>{micTimeoutText}</div>}
        </>
      ) : null}
    </span>
  );
};

export * from './types';
