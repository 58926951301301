import styled from 'styled-components';

export const KSTItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  padding-right: 31px;
  position: relative;
  margin-bottom: 0px;
`;

export const KSTItemSwitch = styled.div`
  display: block;
  float: right;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 21px;
  width: 31px;
  border-radius: 3px;
  border: 1px solid #adb1b8;
  border-top-color: #8d9096;
  background-color: #f0f0f0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%) inset;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

export const KSTItemSwitchControl = styled.a`
  position: absolute;
  left: -1px;
  top: -1px;
  display: block;
  z-index: 1;
  cursor: pointer;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  border: 1px solid #a2a6ac;
  border-top-color: #adb1b8;
  border-bottom-color: #8d9096;
  background: #f5f6f9;
  background: -webkit-linear-gradient(top, #eff1f3, #fff);
  background: linear-gradient(to bottom, #eff1f3, #fff);
  box-shadow: 0 1px 0 0 #fff inset, 0 1px 3px rgb(0 0 0 / 10%) inset, 0 0 1px #fff inset, 0 1px 2px rgb(0 0 0 / 15%);
`;

export const KSTItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`;

export const KSTItemWrapper = styled.div`
  display: block;
`;

export const KSTLearnMoreLink = styled.a`
  font-size: 12px;
  line-height: 16px;
  font-family: verdana, arial, helvetica, sans-serif;
  text-decoration: none;
  color: #007185;
`;
