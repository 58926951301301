import React, { FunctionComponent } from 'react';
import ReactHtmlParser from 'html-react-parser';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import {
  RestrictionMessageBox,
  RestrictionMessageBoxInner,
  RestrictionMessageBoxInnerMshop,
  RestrictionMessageBoxMshop,
  RestrictionMessageIcon
} from './styles';
import { RestrictionMessageProps } from './types';

export const RestrictionMessage: FunctionComponent<RestrictionMessageProps> = (props) => {
  const { restrictionMessage, isMShop } = props;
  return isMShop ? (
    <RestrictionMessageBoxMshop>
      <RestrictionMessageBoxInnerMshop>
        <AlexaText margin="0" textAlign="left" fontSize="14px" lineHeight="15px">
          {ReactHtmlParser(restrictionMessage)}
        </AlexaText>
      </RestrictionMessageBoxInnerMshop>
    </RestrictionMessageBoxMshop>
  ) : (
    <RestrictionMessageBox>
      <RestrictionMessageBoxInner>
        <RestrictionMessageIcon></RestrictionMessageIcon>
        <AlexaText margin="0" textAlign="left" fontSize="12px" lineHeight="15px">
          {ReactHtmlParser(restrictionMessage)}
        </AlexaText>
      </RestrictionMessageBoxInner>
    </RestrictionMessageBox>
  );
};
