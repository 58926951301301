import { StoreActionApi } from 'react-sweet-state';

import { BuyBoxStateMeta } from '../../types';

const update = (state: BuyBoxStateMeta) => ({ setState }: StoreActionApi<BuyBoxStateMeta>) => {
  if (state) {
    setState({ ...state });
  }
};

export default update;
