import React, { FunctionComponent } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { Caret, CaretDirection, CaretSize, Link, SeeMore } from '@amzn/monera-app-core-components';

import {
  defaultDesktopStyleProps,
  defaultMobileStyleProps,
  StyledCaretContainer,
  StyledDivider,
  StyledGadgetSupportContainer,
  StyledGadgetSupportSubContainer,
  StyledLink,
  StyledProductDescriptionContainer
} from './styles';
import { ProductDescriptionProps } from './types';

export const ProductDescription: FunctionComponent<ProductDescriptionProps> = (props) => {
  const { titleCopy, description, seeMoreCopy, seeLessCopy, maxHeight, gadgetSupport } = props;

  const isMobile = maxHeight !== undefined;

  const defaultStyleProps = isMobile ? defaultMobileStyleProps : defaultDesktopStyleProps;

  const getGadgetSupportContainerDesktop = () => {
    if (gadgetSupport) {
      const {
        requirementCopy,
        buttonRequirement,
        playerRequirement,
        echoButtonLink,
        playersCopy,
        gadgetFontSize = '14px',
        gadgetLineHeight = '24px',
        gadgetContainerPadding = '0px 0px 5px 0px'
      } = gadgetSupport;
      return (
        <StyledGadgetSupportContainer gadgetContainerPadding={gadgetContainerPadding}>
          <AlexaText
            inline
            bold
            fontSize={gadgetFontSize}
            lineHeight={gadgetLineHeight}
            data-cy={`gadget-support-requirement-copy`}
          >
            {requirementCopy}:&nbsp;
          </AlexaText>
          <Link
            href={echoButtonLink}
            fontSize={gadgetFontSize}
            lineHeight={gadgetLineHeight}
            data-cy={`gadget-support-echo-button-link`}
          >
            {buttonRequirement}
          </Link>
          <br />
          <AlexaText
            inline
            bold
            fontSize={gadgetFontSize}
            lineHeight={gadgetLineHeight}
            data-cy={`gadget-support-player-copy`}
          >
            {playersCopy}:&nbsp;
          </AlexaText>
          <AlexaText
            inline
            fontSize={gadgetFontSize}
            lineHeight={gadgetLineHeight}
            data-cy={`gadget-support-player-requirement`}
          >
            {playerRequirement}
          </AlexaText>
        </StyledGadgetSupportContainer>
      );
    } else {
      return <> </>;
    }
  };

  const getGadgetSupportContainerMobile = () => {
    if (gadgetSupport) {
      const {
        requirementCopy,
        buttonRequirement,
        playerRequirement,
        echoButtonLink,
        playersCopy,
        gadgetFontSize = '15px',
        gadgetLineHeight = '1.35',
        subContainerPaddingTop = '12px',
        subContainerPaddingBottom = '12px',
        dividerMargin = '0px -17px 0px -17px',
        dividerBorderTop = '1px solid #e7e7e7'
      } = gadgetSupport;
      return (
        <>
          <StyledGadgetSupportSubContainer subContainerPaddingBottom={subContainerPaddingBottom}>
            <StyledLink href={echoButtonLink} textDecorationHover={'none'} data-cy={`gadget-support-echo-button-link`}>
              <AlexaText
                bold
                {...defaultStyleProps.title}
                padding="0px 0px 4px 0px"
                data-cy={'gadget-support-requirement-copy'}
              >
                {requirementCopy}
              </AlexaText>
              <AlexaText
                fontSize={gadgetFontSize}
                lineHeight={gadgetLineHeight}
                data-cy={`gadget-support-button-requirement`}
              >
                {buttonRequirement}
              </AlexaText>
              <StyledCaretContainer>
                <Caret rotation={CaretDirection.Right} size={CaretSize.Medium} />
              </StyledCaretContainer>
            </StyledLink>
          </StyledGadgetSupportSubContainer>

          <StyledDivider dividerBorderTop={dividerBorderTop} dividerMargin={dividerMargin} />

          <StyledGadgetSupportSubContainer
            subContainerPaddingTop={subContainerPaddingTop}
            subContainerPaddingBottom={subContainerPaddingBottom}
          >
            <AlexaText
              bold
              {...defaultStyleProps.title}
              padding="0px 0px 4px 0px"
              data-cy={'gadget-support-player-copy'}
            >
              {playersCopy}
            </AlexaText>
            <AlexaText
              fontSize={gadgetFontSize}
              lineHeight={gadgetLineHeight}
              data-cy={`gadget-support-player-requirement`}
            >
              {playerRequirement}
            </AlexaText>
          </StyledGadgetSupportSubContainer>

          <StyledDivider
            dividerBorderTop={dividerBorderTop}
            dividerMargin={dividerMargin}
            subContainerPaddingTop={subContainerPaddingTop}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <StyledProductDescriptionContainer isMobile={isMobile} data-cy={'skill-product-description-container'}>
      {isMobile && getGadgetSupportContainerMobile()}
      <AlexaText
        bold={true}
        {...defaultStyleProps.title}
        padding="0px 0px 4px 0px"
        aria-level={2}
        data-cy={'skill-product-description-title'}
      >
        {titleCopy}
      </AlexaText>
      {!isMobile && getGadgetSupportContainerDesktop()}
      <SeeMore
        whiteSpace="pre-line"
        maxHeight={maxHeight}
        seeMoreCopy={seeMoreCopy}
        seeLessCopy={seeLessCopy}
        data-cy={'skill-product-description-see-more'}
      >
        {description}
      </SeeMore>
    </StyledProductDescriptionContainer>
  );
};

export * from './types';
