import { StoreActionApi } from 'react-sweet-state';

import { BuyBoxStateMeta } from '../../types';

const init = (state: BuyBoxStateMeta) => ({ setState }: StoreActionApi<BuyBoxStateMeta>) => {
  if (state) {
    setState(state);
  }
};

export default init;
