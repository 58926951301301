export interface FreeSkillAlertBannerGroupProps {
  alertMessages: FreeSkillAlertBannerProps[];
}

export interface FreeSkillAlertBannerProps {
  alertTitleText: string;
  alertText: string;
  alertBannerType: AlertBannerType;
}

export enum AlertBannerType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info'
}
