import { createHook, createStore, createSubscriber } from 'react-sweet-state';

import { BuyBoxActions, buyBoxActions } from './actions';
import { initialBuyBoxState } from './state';
import { BuyBoxState } from './types';

export const BUY_BOX_STORE_NAME = 'free-skill-buy-box-store';

export const buyBoxStore = createStore<BuyBoxState, BuyBoxActions>({
  actions: buyBoxActions,
  initialState: initialBuyBoxState,
  name: BUY_BOX_STORE_NAME
});

export const BuyBoxSubscriber = createSubscriber(buyBoxStore);

export const useBuyBoxStore = createHook(buyBoxStore);

export * from './types';
