import { createHook, createStore, createSubscriber } from 'react-sweet-state';

import { BuyBoxMetaActions, buyBoxMetaActions } from './actions';
import { initialBuyBoxMetaState } from './state';
import { BuyBoxStateMeta } from './types';

export const BUY_BOX_META_STORE_NAME = 'free-skill-buy-box-meta-store';

export const buyBoxMetaStore = createStore<BuyBoxStateMeta, BuyBoxMetaActions>({
  actions: buyBoxMetaActions,
  initialState: initialBuyBoxMetaState,
  name: BUY_BOX_META_STORE_NAME
});

export const BuyBoxMetaSubscriber = createSubscriber(buyBoxMetaStore);

export const useBuyBoxMetaStore = createHook(buyBoxMetaStore);

export * from './types';
