import React, { FunctionComponent } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { CanvasContainer, CanvasItem } from '@amzn/monera-app-core-components';

import { alexaTheme } from '../../theme';
import { MShopDownloadAlexaAppButton } from './styles';
import { MShopDownloadAlexaAppProps } from './types';

export const MShopDownloadAlexaApp: FunctionComponent<MShopDownloadAlexaAppProps> = (props) => {
  const { mshopDownloadAlexaAppButtonText, mshopDownloadAlexaAppMsg, mshopDownloadAlexaAppUrl } = props;

  const openDownloadAlexaAppUrl = () => {
    window.open(mshopDownloadAlexaAppUrl, '_blank');
  };

  return (
    <CanvasContainer direction="column" alignContent="center" padding="10px 0px 0px 0px">
      <CanvasItem margin="0px 0px 10px 0px" width="100%">
        <AlexaText fontSize={alexaTheme.typography?.mobileBody?.fontSize}>{mshopDownloadAlexaAppMsg}</AlexaText>
      </CanvasItem>
      <CanvasItem margin="0px 0px 10px 0px" width="100%">
        <MShopDownloadAlexaAppButton onClick={openDownloadAlexaAppUrl}>
          {mshopDownloadAlexaAppButtonText}
        </MShopDownloadAlexaAppButton>
      </CanvasItem>
    </CanvasContainer>
  );
};

export * from './types';
