import { StoreActionApi } from 'react-sweet-state';

import { AlertBannerState } from '../../types';

const update = (state: AlertBannerState) => ({ setState }: StoreActionApi<AlertBannerState>) => {
  if (state) {
    setState({ ...state });
  }
};

export default update;
