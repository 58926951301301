import React, { FunctionComponent } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { Button, CloseButton, Modal } from '@amzn/monera-app-core-components';

import { alexaTypography } from '../../theme';
import {
  DynamicContentModalBox,
  DynamicContentModalFooter,
  DynamicContentModalTextBox,
  DynamicContentModalTitleBox
} from './styles';
import { DynamicContentModalProps } from './types';

export const DynamicContentModal: FunctionComponent<DynamicContentModalProps> = (props: DynamicContentModalProps) => {
  const { isOpen, close, title, description, buttonCopy, isMobile = false } = props;

  const defaultModalHeaderTextProps = isMobile ? alexaTypography.mobileModalHeader : alexaTypography.desktopModalHeader;
  const defaultModalContentTextProps = isMobile ? alexaTypography.mobileBody : alexaTypography.desktopBody;

  return (
    <Modal isOpen={isOpen} close={close}>
      <DynamicContentModalBox>
        <DynamicContentModalTitleBox>
          <AlexaText {...defaultModalHeaderTextProps} bold padding="10px 0 9px" data-cy="dynamic-content-modal-title">
            {title}
          </AlexaText>
          <CloseButton onClick={close} />
        </DynamicContentModalTitleBox>
        <DynamicContentModalTextBox>
          {description && (
            <AlexaText {...defaultModalContentTextProps} data-cy="dynamic-content-description" margin="0 0 4px 0">
              {description}
            </AlexaText>
          )}
        </DynamicContentModalTextBox>
        <DynamicContentModalFooter>
          <Button
            width="fit-content"
            height="fit-content"
            padding="0 10px"
            aria-label={buttonCopy}
            copy={buttonCopy}
            data-cy="dynamic-content-button"
            onClick={close}
          />
        </DynamicContentModalFooter>
      </DynamicContentModalBox>
    </Modal>
  );
};

export * from './types';
