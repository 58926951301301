import {
  actionMutation,
  AuthHeadersEnum,
  HTTPHeaders,
  isError,
  mutateMoneraService,
  MutateMoneraServiceProps,
  ShoppingPortalHeadersEnum
} from '@amzn/monera-app-core';
import { emitLatencyMetrics, meterFatalsFromResponse } from '@amzn/monera-app-core/lib/utils/metric-helper';
import { serverConsole } from '@amzn/monera-app-core/lib/utils/server';

import { RehydrateBuyBoxRequest } from './types';

export const rehydrateBuyBox = async (rehydrateBuyBoxRequest: RehydrateBuyBoxRequest) => {
  const {
    requestId,
    asin,
    tenant,
    marketplaceId,
    ubid,
    sessionId,
    customerId,
    deviceType,
    locale,
    defaultLocale,
    localizedProductDetails,
    csrfToken
  } = rehydrateBuyBoxRequest;

  const rehydrateMutationProps: MutateMoneraServiceProps = {
    gqlMutation: actionMutation,
    variables: {
      asin,
      tenant,
      actionType: 'rehydrate',
      marketplaceId,
      data: JSON.stringify(localizedProductDetails),
      customerId
    },
    headers: {
      [ShoppingPortalHeadersEnum.MarketplaceId]: marketplaceId,
      [ShoppingPortalHeadersEnum.UBID]: ubid,
      [ShoppingPortalHeadersEnum.SessionId]: sessionId,
      [ShoppingPortalHeadersEnum.Cookie]: document.cookie,
      [AuthHeadersEnum.AntiCsrfToken]: csrfToken,
      [HTTPHeaders.RequestId]: requestId,
      deviceType,
      locale,
      defaultLocale
    }
  };
  serverConsole.log('REHYDRATE PROPS: ', rehydrateMutationProps);

  // rehydrate mutation metrics
  const methodName = 'RehydrateBuyBoxMutation';
  const metricName = 'RehydrateBuyBoxMutation';
  const startTime = Date.now();

  let response;
  try {
    response = await mutateMoneraService<{ response: any }>(rehydrateMutationProps);
  } catch (ex) {
    serverConsole.log('error rehydrating: ', ex);
  }

  const mutateMoneraServiceResponseTime = Date.now() - startTime;
  emitLatencyMetrics(methodName, metricName, mutateMoneraServiceResponseTime, requestId, tenant);

  meterFatalsFromResponse(response, {
    methodName: 'RehydrateBuyBox',
    metricName,
    tenant: 'ALEXA_FREE_SKILLS',
    requestId
  });
  if (response === undefined || isError(response)) {
    serverConsole.log('error rehydating buybox');
    return undefined;
  } else {
    serverConsole.log('RES: ', response);
    return JSON.parse((response.data as any)?.action?.response);
  }
};

export * from './types';
