import { createContainer, createHook, createStore } from 'react-sweet-state';

import { AlertBannerActions, alertBannerActions } from './actions';
import { initialAlertBannerState } from './state';
import { AlertBannerContainerSelectorProps, AlertBannerState } from './types';

export const ALERT_BANNER_STORE_NAME = 'free-skill-alert-banner-group-store';

export const alertBannerStore = createStore<AlertBannerState, AlertBannerActions>({
  actions: alertBannerActions,
  initialState: initialAlertBannerState,
  name: ALERT_BANNER_STORE_NAME
});

export const useAlertBannerStore = createHook(alertBannerStore);

export const AlertBannerContainer = createContainer<
  AlertBannerState,
  AlertBannerActions,
  AlertBannerContainerSelectorProps
>(alertBannerStore, {
  onInit: () => ({ setState }, { initialState }) => setState(initialState ?? initialAlertBannerState),
  onUpdate: () => ({ setState }, { initialState }) => setState(initialState ?? initialAlertBannerState)
});

export * from './types';
