import styled from 'styled-components';

export const RestrictionMessageBox = styled.div`
  margin: 8px 0 4px 0;
  display: inline-block;
  border: none;
  vertical-align: middle;
  background-color: transparent;
`;

export const RestrictionMessageBoxInner = styled.div`
  padding: 0 0 0 22px;
  color: #0f1111;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const RestrictionMessageBoxMshop = styled.div`
  margin: 8px 0 4px 0;
  display: inline-block;
  border: none;
  vertical-align: middle;
  background-color: transparent;
`;

export const RestrictionMessageBoxInnerMshop = styled.div`
  padding: 0;
  color: #0f1111;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const RestrictionMessageIcon = styled.i`
  height: 13px;
  width: 14px;
  position: absolute;
  left: 2px;
  top: 2px;
  background-position: -148px -86px;
  background-image: url(https://m.media-amazon.com/images/S/sash/f9Cwl2OUDVHGXk8.png);
  display: inline-block;
  vertical-align: top;
  background-size: 400px 900px;
  background-repeat: no-repeat;
`;
