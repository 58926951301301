import React, { FunctionComponent } from 'react';

import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { Button, CloseButton, Modal } from '@amzn/monera-app-core-components';

import { alexaTypography } from '../../theme';
import { MicTimeoutModalBox, MicTimeoutModalFooter, MicTimeoutModalTextBox, MicTimeoutModalTitleBox } from './styles';
import { MicTimeoutModalProps } from './types';

export const MicTimeoutModal: FunctionComponent<MicTimeoutModalProps> = (props: MicTimeoutModalProps) => {
  const { isOpen, close, title, description, buttonCopy, isMobile = false } = props;

  const defaultModalHeaderTextProps = isMobile ? alexaTypography.mobileModalHeader : alexaTypography.desktopModalHeader;
  const defaultModalContentTextProps = isMobile ? alexaTypography.mobileBody : alexaTypography.desktopBody;

  return (
    <Modal isOpen={isOpen} close={close}>
      <MicTimeoutModalBox>
        <MicTimeoutModalTitleBox>
          <AlexaText {...defaultModalHeaderTextProps} bold padding="10px 0 9px" data-cy="mic-timeout-modal-title">
            {title}
          </AlexaText>
          <CloseButton onClick={close} />
        </MicTimeoutModalTitleBox>
        <MicTimeoutModalTextBox>
          {description && (
            <AlexaText {...defaultModalContentTextProps} data-cy="mic-timeout-description" margin="0 0 4px 0">
              {description}
            </AlexaText>
          )}
        </MicTimeoutModalTextBox>
        <MicTimeoutModalFooter>
          <Button
            width="fit-content"
            height="fit-content"
            padding="0 10px"
            aria-label={buttonCopy}
            copy={buttonCopy}
            data-cy="mic-timeout-button"
            onClick={close}
          />
        </MicTimeoutModalFooter>
      </MicTimeoutModalBox>
    </Modal>
  );
};

export * from './types';
