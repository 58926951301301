import { coreBaseFont, coreTheme, RecursivePartial } from '@amzn/monera-app-core';

import { AlexaTheme, AlexaTypography } from './type';

// AlexaTypography

export const alexaTypography: RecursivePartial<AlexaTypography> = {
  ...coreTheme.typography,
  desktopH1: {
    ...coreBaseFont,
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '36px'
  },
  desktopH2: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32px'
  },
  desktopH3: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px'
  },
  desktopBody: {
    ...coreBaseFont,
    fontSize: '14px',
    lineHeight: '20px'
  },
  desktopMD: {
    ...coreBaseFont,
    fontSize: '18px',
    lineHeight: '24px'
  },
  desktopSM: {
    ...coreBaseFont,
    fontSize: '12px',
    lineHeight: '16px'
  },
  desktopModalHeader: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px'
  },
  mobileH1: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '1.3'
  },
  mobileH2: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '1.3'
  },
  mobileH3: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '1.25'
  },
  mobileBody: {
    ...coreBaseFont,
    fontSize: '15px',
    lineHeight: '1.35'
  },
  mobileMD: {
    ...coreBaseFont,
    fontSize: '18px',
    lineHeight: '1.25'
  },
  mobileSM: {
    ...coreBaseFont,
    fontSize: '13px',
    lineHeight: '1.4'
  },
  mobileModalHeader: {
    ...coreBaseFont,
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px'
  }
};

// TODO: Create alexa theme
export const alexaTheme: AlexaTheme = {
  ...coreTheme,
  typography: alexaTypography
};
