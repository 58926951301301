import { MShopDownloadAlexaAppProps } from '../../..';

export enum ButtonActionTypes {
  Enable = 'ENABLE',
  Disable = 'DISABLE',
  LinkAccount = 'LINK_ACCOUNT',
  ManagePermissions = 'MANAGE_PERMISSIONS'
}
export interface BuyBoxState {
  restrictionMessage?: string;
  headingCopy?: string;
  enableCopy?: string;
  footerCopy?: string;
  actionBoxData?: ActionBoxDataProps;
  permissionsInfoData?: PermissionsInfoDataProps;
  disableModalData?: DisableModalDataProps;
  discoverDeviceModalData?: DiscoverDeviceModalData;
  compatibilityWidgetData?: CompatibilityWidgetDataProps;
  skillTitle?: string;
  isAuthenticatedUser?: boolean;
  isSkillEnabled?: boolean;
  buyBoxMetadata?: BuyBoxMetadataProps;
  deviceType?: string;
  locale?: string;
  defaultLocale?: string;
  localizedProductDetails?: any;
  firstPartySkillFlag?: boolean;
  mshopDownloadAlexaAppData?: MShopDownloadAlexaAppProps;
  drsManageDeviceText?: string;
}

export interface BuyBoxMetadataProps {
  csrfToken?: string;
}
export interface CompatibilityWidgetDataProps {
  compatibilityPopoverText: string;
  popoverContentList: string[];
  deviceInfoText: string;
  deviceInfoURL: string;
}
export interface ActionBoxDataProps {
  accountLinkRequired?: boolean;
  primaryButtonData: ButtonDataProps;
  secondaryButtonData?: ButtonDataProps;
  enableButtonText: string;
  disableButtonText: string;
  accountLinkURI?: string;
  additionalAccountLinkData?: AdditionalAccountLinkData;
  parentalConsentMessageData?: ParentalConsentMessageDataProps;
  priceInfoData?: PriceInfoDataProps;
  buttonEnablingText?: string;
  buttonDisablingText?: string;
  contentSkillData?: ContentSkillData;
  skillSpecificMessage?: string;
  isSmartHomeSkill?: boolean;
}

export interface AdditionalAccountLinkData {
  accountLinkingText?: string;
  doAccountLinkOnEnable?: boolean;
}

export interface PriceInfoDataProps {
  priceText: string;
  additionalPriceText?: string;
}
export interface ParentalConsentMessageDataProps {
  kstModalData?: AlexaKSTModalDataProps;
  kstStatus?: boolean;
  kstRequired?: boolean;
  learnMoreText: string;
  learnMoreLink: string;
  parentalConsentMessage: string;
  vpcStatus?: boolean;
  vpcRequired?: boolean;
}

export interface ButtonDataProps {
  buttonText: string;
  buttonAction: string;
}

export interface PermissionsInfoDataProps {
  buyBoxPermissionsHeader: string;
  permissionsModalHeader: string;
  permissionsModalBodyHeaderText?: string;
  permissionsModalSaveButtonText?: string;
  permissionsInfoList: PermissionsInfoProps[];
  permissionButtonData?: ButtonDataProps;
  permissionsSavingSettings: string;
}

export interface PermissionsInfoProps {
  id: string;
  name: string;
  description: string;
  defaultValue: boolean;
  consented: boolean;
  consentlessScope: boolean;
  note: string;
}

export interface DisableModalDataProps {
  disableModalTitle: string;
  disableModalBody: string;
  disableModalConfirmText: string;
  disableModalCancelText: string;
}
export interface AlexaKSTModalDataProps {
  kstHeaderTitle: string;
  kstModalBodyProps: KSTModalBodyDataProps;
  okButtonText: string;
  closeButtonText: string;
}

export interface KSTModalBodyDataProps {
  kstName: string;
  kstDescription: string;
}

export interface ContentSkillData {
  isContentSkill?: boolean;
  manageContentURI?: string;
  manageContentText?: string;
}

export interface DiscoverDeviceModalData {
  discoverDeviceModalTitle: string;
  discoverDeviceModalBody: string;
  discoverDeviceModalCancel: string;
  discoverDeviceModalDiscoverDevices: string;
}
