import {
  actionMutation,
  AuthHeadersEnum,
  HTTPHeaders,
  isError,
  mutateMoneraService,
  MutateMoneraServiceProps,
  ShoppingPortalHeadersEnum
} from '@amzn/monera-app-core';
import { emitLatencyMetrics, meterFatalsFromResponse } from '@amzn/monera-app-core/lib/utils/metric-helper';
import { serverConsole } from '@amzn/monera-app-core/lib/utils/server';

import { GetVPCTokenValidityRequest, GetVPCWidgetDataRequest, SetKSTStatusRequest } from './types';

export const getVPCWidgetData = async (getVPCWidgetData: GetVPCWidgetDataRequest) => {
  // TODO: Implement save/update permissions API call
  const { requestId, asin, tenant, marketplaceId, ubid, sessionId, customerId, csrfToken } = getVPCWidgetData;

  const getVPCWidgetMutationProps: MutateMoneraServiceProps = {
    gqlMutation: actionMutation,
    variables: {
      asin,
      tenant,
      actionType: 'getvpcwidget',
      marketplaceId,
      data: JSON.stringify({ make: 'money' }),
      customerId
    },
    headers: {
      [ShoppingPortalHeadersEnum.MarketplaceId]: marketplaceId,
      [ShoppingPortalHeadersEnum.UBID]: ubid,
      [ShoppingPortalHeadersEnum.SessionId]: sessionId,
      [AuthHeadersEnum.AntiCsrfToken]: csrfToken,
      [HTTPHeaders.RequestId]: requestId
    }
  };
  serverConsole.log('getvpcwidget PROPS: ', getVPCWidgetMutationProps);

  // Enable skill mutation metrics
  const methodName = 'GetVPCWidgetMutation';
  const metricName = 'GetVPCWidgetMutation';
  const startTime = Date.now();

  let response;
  try {
    response = await mutateMoneraService<{ response: any }>(getVPCWidgetMutationProps);
  } catch (ex) {
    serverConsole.log('error getting VPC widget data');
  }

  const mutateMoneraServiceResponseTime = Date.now() - startTime;
  emitLatencyMetrics(methodName, metricName, mutateMoneraServiceResponseTime, requestId, tenant);

  // eslint-disable-next-line no-console
  serverConsole.log('getVPCWidget Data RESPONSE', response);

  meterFatalsFromResponse(response, {
    methodName: 'GetVPCWidgetData',
    metricName,
    tenant: 'ALEXA_FREE_SKILLS',
    requestId
  });
  if (response === undefined || isError(response)) {
    serverConsole.log('error getting vpc widget data');
    return false;
  } else {
    const vpcUrl = (response as any).data?.action?.response + '&clientParams=' + window.location.href;
    serverConsole.log('vpcURL: ', vpcUrl);
    window.location.href = vpcUrl;
  }
  return false;
};

export const getVPCTokenValidity = async (getVPCTokenValidityData: GetVPCTokenValidityRequest) => {
  const {
    requestId,
    asin,
    tenant,
    marketplaceId,
    ubid,
    sessionId,
    customerId,
    vpcToken,
    csrfToken
  } = getVPCTokenValidityData;

  const getVPCTokenValidityMutationProps: MutateMoneraServiceProps = {
    gqlMutation: actionMutation,
    variables: {
      asin,
      tenant,
      actionType: 'getvpctokenvalidity',
      marketplaceId,
      data: vpcToken,
      customerId
    },
    headers: {
      [ShoppingPortalHeadersEnum.MarketplaceId]: marketplaceId,
      [ShoppingPortalHeadersEnum.UBID]: ubid,
      [ShoppingPortalHeadersEnum.SessionId]: sessionId,
      [AuthHeadersEnum.AntiCsrfToken]: csrfToken,
      [HTTPHeaders.RequestId]: requestId
    }
  };
  // eslint-disable-next-line no-console
  serverConsole.log('getvpctokenvalidity PROPS: ', getVPCTokenValidityMutationProps);

  // Enable skill mutation metrics
  const methodName = 'GetVPCTokenValidityMutation';
  const metricName = 'GetVPCTokenValidityMutation';
  const startTime = Date.now();

  let response;
  try {
    response = await mutateMoneraService<{ response: any }>(getVPCTokenValidityMutationProps);
  } catch (ex) {
    serverConsole.log('error getting VPC token validity', ex);
  }

  const mutateMoneraServiceResponseTime = Date.now() - startTime;
  emitLatencyMetrics(methodName, metricName, mutateMoneraServiceResponseTime, requestId, tenant);

  // eslint-disable-next-line no-console
  serverConsole.log('get VPC token validity Data RESPONSE', response);

  meterFatalsFromResponse(response, {
    methodName: 'GetVPCTokenValidity',
    metricName,
    tenant: 'ALEXA_FREE_SKILLS',
    requestId
  });
  if (response === undefined || isError(response)) {
    serverConsole.log('error getting vpc token validity');
    return false;
  } else {
    return (response as any).data?.action?.response === 'Valid';
  }
};

export const setKSTStatus = async (setKSTStatusRequest: SetKSTStatusRequest) => {
  const {
    requestId,
    asin,
    tenant,
    marketplaceId,
    ubid,
    sessionId,
    customerId,
    status,
    csrfToken
  } = setKSTStatusRequest;

  const setKSTStatusRequestMutationProps: MutateMoneraServiceProps = {
    gqlMutation: actionMutation,
    variables: {
      asin,
      tenant,
      actionType: 'setkststatus',
      marketplaceId,
      data: status,
      customerId
    },
    headers: {
      [ShoppingPortalHeadersEnum.MarketplaceId]: marketplaceId,
      [ShoppingPortalHeadersEnum.UBID]: ubid,
      [ShoppingPortalHeadersEnum.SessionId]: sessionId,
      [AuthHeadersEnum.AntiCsrfToken]: csrfToken,
      [HTTPHeaders.RequestId]: requestId
    }
  };
  // eslint-disable-next-line no-console
  serverConsole.log('setkststatus PROPS: ', setKSTStatusRequestMutationProps);

  // Enable skill mutation metrics
  const methodName = 'SetKSTStatusMutation';
  const metricName = 'SetKSTStatusMutation';
  const startTime = Date.now();

  let response;
  try {
    response = await mutateMoneraService<{ response: any }>(setKSTStatusRequestMutationProps);
  } catch (ex) {
    serverConsole.log('error setting KST status: ', ex);
  }
  const mutateMoneraServiceResponseTime = Date.now() - startTime;
  emitLatencyMetrics(methodName, metricName, mutateMoneraServiceResponseTime, requestId, tenant);

  // eslint-disable-next-line no-console
  serverConsole.log('setKSTStatus RESPONSE', response);

  meterFatalsFromResponse(response, { methodName: 'SetKSTStatus', metricName, tenant: 'ALEXA_FREE_SKILLS', requestId });
  if (response === undefined || isError(response)) {
    serverConsole.log('error setting kst status');
    return false;
  } else {
    return (response as any).data?.action?.response === 'true';
  }
};

export * from './types';
