import styled from 'styled-components';

import { Box } from '@amzn/monera-app-core-components';

export const MicTimeoutModalBox = styled(Box)`
  background-color: rgb(255, 255, 255);
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 6px rgb(0 0 0 / 25%);
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 45px;
`;

export const MicTimeoutModalTextBox = styled(Box)`
  background-color: #fff;
  padding: 14px 18px;
  text-align: left;
  overflow-x: hidden;
`;

export const MicTimeoutModalTitleBox = styled(Box)`
  background: #f2f2f2;
  background: #f1f1f1;
  background: -webkit-linear-gradient(top, #f7f7f7, #eaeaea);
  background: linear-gradient(to bottom, #f7f7f7, #eaeaea);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #cdcdcd;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgb(255 255 255 / 50%) inset, 0 -1px 0 rgb(255 255 255 / 40%) inset;
  text-align: left;
  padding: 0 4px 0 14px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
`;

export const MicTimeoutModalFooter = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  background-color: #f3f3f3;
  background: #f9f9f9;
  background: -webkit-linear-gradient(top, #f3f3f3, #fff);
  background: linear-gradient(to bottom, #f3f3f3, #fff);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
  border-top: 1px solid #ddd;
  padding: 14px;
  margin: 0;
  border-radius: 0 0 4px 4px;
`;
