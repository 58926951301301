import {
  actionMutation,
  AuthHeadersEnum,
  HTTPHeaders,
  isError,
  mutateMoneraService,
  MutateMoneraServiceProps,
  serverConsole,
  ShoppingPortalHeadersEnum
} from '@amzn/monera-app-core';
import { emitLatencyMetrics, meterFatalsFromResponse } from '@amzn/monera-app-core/lib/utils/metric-helper';

import { EnableSkillRequest } from './types';

export const enableSkill = async (enableSkill: EnableSkillRequest) => {
  // TODO: Implement save/update permissions API call
  const {
    requestId,
    asin,
    tenant,
    marketplaceId,
    ubid,
    sessionId,
    csrfToken,
    customerId,
    isSmartHome,
    deviceType,
    locale,
    defaultLocale,
    localizedProductDetails
  } = enableSkill;
  const enableMutationProps: MutateMoneraServiceProps = {
    gqlMutation: actionMutation,
    variables: {
      asin,
      tenant,
      actionType: isSmartHome ? 'smarthomeenable' : 'enable',
      marketplaceId,
      data: JSON.stringify(localizedProductDetails),
      customerId
    },
    headers: {
      [ShoppingPortalHeadersEnum.MarketplaceId]: marketplaceId,
      [ShoppingPortalHeadersEnum.UBID]: ubid,
      [ShoppingPortalHeadersEnum.SessionId]: sessionId,
      [ShoppingPortalHeadersEnum.Cookie]: document.cookie,
      [AuthHeadersEnum.AntiCsrfToken]: csrfToken,
      [HTTPHeaders.RequestId]: requestId,
      deviceType,
      locale,
      defaultLocale
    }
  };
  serverConsole.log('ENABLE PROPS: ', enableMutationProps);

  // Enable skill mutation metrics
  const methodName = 'EnableSkillMutation';
  const metricName = 'EnableSkillMutation';
  const startTime = Date.now();

  const response = await mutateMoneraService<{ response: any }>(enableMutationProps).catch((error) =>
    serverConsole.log('error enabling: ', error)
  );

  const mutateMoneraServiceResponseTime = Date.now() - startTime;
  emitLatencyMetrics(methodName, metricName, mutateMoneraServiceResponseTime, requestId, tenant);

  meterFatalsFromResponse(response, {
    methodName: 'EnableSkill',
    metricName,
    tenant: 'ALEXA_FREE_SKILLS',
    requestId
  });
  if (response === undefined || isError(response)) {
    serverConsole.log('error enabling skill');
    return undefined;
  } else {
    return JSON.parse((response.data as any)?.action?.response);
  }
};

export * from './types';
