import React, { FunctionComponent } from 'react';

import { AlexaModal, AlexaModalProps, AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { serverConsole, useConfigStore, useShoppingPortalStore } from '@amzn/monera-app-core';

import { disableSkill } from '../../services';
import { BuyBoxState, useBuyBoxStore } from '../../state/stores/free-skill-buy-box';
import { AlexaDisableSkillModalProps } from './types';

export const AlexaDisableSkillModal: FunctionComponent<AlexaDisableSkillModalProps> = (props) => {
  const {
    modalTitleCopy,
    disableSkillBodyCopy,
    disableButtonCopy,
    cancelButtonCopy,
    modalProps,
    setIsDisabling,
    deviceType,
    locale,
    defaultLocale,
    localizedProductDetails
  } = props;
  const [configRegistryState] = useConfigStore();
  const [shoppingPortalRegistryState] = useShoppingPortalStore();
  const [buyBoxState, buyBoxActions] = useBuyBoxStore();

  const doDisableSkill = async () => {
    setIsDisabling(true);
    const response = await disableSkill({
      requestId: configRegistryState.requestId,
      asin: configRegistryState.asin,
      tenant: configRegistryState.tenant ?? '',
      marketplaceId: shoppingPortalRegistryState.marketplaceId,
      sessionId: shoppingPortalRegistryState.sessionId,
      ubid: shoppingPortalRegistryState.ubid,
      csrfToken: configRegistryState.csrfToken,
      customerId: shoppingPortalRegistryState.customerId,
      deviceType,
      locale,
      defaultLocale,
      localizedProductDetails
    });
    serverConsole.log('DISABLE RESPONSE: ', JSON.stringify(response));
    if (response) {
      buyBoxActions.update(response as BuyBoxState);
      serverConsole.log('bba.update after disable: ', JSON.stringify(buyBoxState));
    }
    setIsDisabling(false);
  };

  const getModalBodyContent = () => {
    return <AlexaText copy={disableSkillBodyCopy} />;
  };

  const alexaModalProps: AlexaModalProps = {
    modalTitleCopy,
    modalBodyContent: getModalBodyContent(),
    acceptButtonCopy: disableButtonCopy,
    closeButtonCopy: cancelButtonCopy,
    onAcceptAction: doDisableSkill,
    modalProps
  };

  return <AlexaModal {...alexaModalProps} />;
};

export * from './types';
