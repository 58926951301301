import React from 'react';
import styled, { css } from 'styled-components';

import { Link } from '@amzn/monera-app-core-components';

import { alexaTheme } from '../../theme';
import { GadgetSupportProps, ProductDescriptionContainerProps } from './types';

export const defaultDesktopStyleProps = {
  title: {
    fontSize: alexaTheme.typography?.desktopH2?.fontSize,
    lineHeight: alexaTheme.typography?.desktopH2?.lineHeight
  },
  description: {
    fontSize: alexaTheme.typography?.desktopBody?.fontSize,
    lineHeight: alexaTheme.typography?.desktopBody?.lineHeight
  }
};

export const defaultMobileStyleProps = {
  title: {
    fontSize: alexaTheme.typography?.mobileH3?.fontSize,
    lineHeight: alexaTheme.typography?.mobileH3?.lineHeight
  },
  description: {
    fontSize: alexaTheme.typography?.mobileBody?.fontSize,
    lineHeight: alexaTheme.typography?.mobileBody?.lineHeight
  }
};

export const StyledProductDescriptionContainer = styled.div`
  ${(props: ProductDescriptionContainerProps) => {
    const { isMobile } = props;
    const defaultStyleProps = isMobile ? defaultMobileStyleProps : defaultDesktopStyleProps;
    return css`
      font-size: ${defaultStyleProps.description.fontSize};
      line-height: ${defaultStyleProps.description.lineHeight};
      width: 100%;
    `;
  }}
`;

export const StyledGadgetSupportContainer = styled.div`
  ${(props: GadgetSupportProps) => {
    const { gadgetContainerPadding } = props;
    return css`
      padding: ${gadgetContainerPadding};
    `;
  }}
`;

export const StyledGadgetSupportSubContainer = styled.div`
  position: relative;
  ${(props: GadgetSupportProps) => {
    const { subContainerPaddingTop, subContainerPaddingBottom } = props;
    return css`
      padding-top: ${subContainerPaddingTop};
      padding-bottom: ${subContainerPaddingBottom};
    `;
  }}
`;

export const StyledLink = styled((props) => <Link {...props} />)`
  color: black !important;
  &:hover {
    color: #c45500 !important;
    cursor: pointer !important;
  }
`;

export const StyledCaretContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.7rem;
`;

export const StyledDivider = styled.div`
  ${(props: GadgetSupportProps) => {
    const { dividerMargin, dividerBorderTop, subContainerPaddingTop, subContainerPaddingBottom } = props;
    return css`
      border-top: ${dividerBorderTop};
      margin: ${dividerMargin};
      padding-top: ${subContainerPaddingTop};
      padding-bottom: ${subContainerPaddingBottom};
    `;
  }}
`;
