import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  AlexaButton,
  AlexaPermissionModal,
  AlexaPermissionModalProps,
  AlexaText
} from '@amzn/monera-app-alexa-shared-libs';
import { serverConsole, useConfigStore, useShoppingPortalStore } from '@amzn/monera-app-core';
import { CanvasContainer, List } from '@amzn/monera-app-core-components';

import { rehydrateBuyBox } from '../../services';
import { BuyBoxState, useBuyBoxStore } from '../../state/stores/free-skill-buy-box';
import { alexaTypography } from '../../theme';
import { AlexaSkillPermissionsProps } from './types';

export const AlexaSkillPermissions: FunctionComponent<AlexaSkillPermissionsProps> = (props) => {
  const [buyBoxState, buyBoxActions] = useBuyBoxStore();
  const [configRegistryState] = useConfigStore();
  const [shoppingPortalRegistryState] = useShoppingPortalStore();
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState<boolean>(false);
  const [isSavingPermissions, setIsSavingPermissions] = useState<boolean>(false);

  const {
    permissionsInfoData,
    isAuthenticatedUser,
    isSkillEnabled,
    deviceType,
    locale,
    defaultLocale,
    localizedProductDetails
  } = buyBoxState;

  const { titleCopy, descriptionCopy, permissionNames } = props;

  useEffect(() => {
    window.addEventListener('showPermissionModal', handlePermissionModalOpen);
    return () => window.removeEventListener('showPermissionModal', handlePermissionModalOpen);
  }, []);

  const handlePermissionModalClose = () => {
    setIsPermissionModalOpen(false);
  };

  const handlePermissionModalOpen = async () => {
    serverConsole.log('calling rehydrate');
    const response = await rehydrateBuyBox({
      requestId: configRegistryState.requestId,
      asin: configRegistryState.asin,
      tenant: configRegistryState.tenant ?? '',
      marketplaceId: shoppingPortalRegistryState.marketplaceId,
      sessionId: shoppingPortalRegistryState.sessionId,
      ubid: shoppingPortalRegistryState.ubid,
      csrfToken: configRegistryState.csrfToken,
      customerId: shoppingPortalRegistryState.customerId,
      deviceType,
      locale,
      defaultLocale,
      localizedProductDetails
    });
    if (response) {
      buyBoxActions.update(response as BuyBoxState);
    }
    setIsPermissionModalOpen(true);
  };

  const alexaPermissionModalProps: AlexaPermissionModalProps = {
    modalTitleCopy: permissionsInfoData?.permissionsModalHeader ?? '',
    permissionsModalBodyHeaderText: permissionsInfoData?.permissionsModalBodyHeaderText ?? '',
    permissions: permissionsInfoData?.permissionsInfoList ?? [],
    permissionsModalSaveButtonText: permissionsInfoData?.permissionsModalSaveButtonText ?? '',
    setIsSavingPermissions,
    modalProps: {
      isOpen: isPermissionModalOpen,
      close: handlePermissionModalClose
    }
  };

  const listItems = permissionNames.map((permissionName, i) => {
    return (
      <AlexaText
        {...alexaTypography.mobileBody}
        data-cy={`alexa-skill-permissions-list-item-${i}`}
        key={`${i}-${permissionName}`}
      >
        {permissionName}
      </AlexaText>
    );
  });

  return permissionNames.length > 0 ? (
    <CanvasContainer border="1px solid #e7e7e7" padding="13px 17px 12px 17px">
      <AlexaText {...alexaTypography.mobileH3} aria-level={2} data-cy={'alexa-skill-permissions-title'}>
        {titleCopy}
      </AlexaText>
      <AlexaText {...alexaTypography.mobileBody}>{descriptionCopy}</AlexaText>
      <List
        padding={'0 0 0px 18px'}
        items={listItems}
        margin={'4px 4px 4px 4px'}
        data-cy={'alexa-skill-permissions-list'}
      />
      {isAuthenticatedUser &&
        isSkillEnabled &&
        (!isSavingPermissions ? (
          <>
            <AlexaPermissionModal {...alexaPermissionModalProps} />
            <AlexaButton
              aria-label={permissionsInfoData?.permissionButtonData?.buttonText ?? 'Manage Permissions'}
              fontSize="16px"
              lineHeight="1.35"
              padding="12px 16px 12px 16px"
              height="auto"
              background="white"
              backgroundHover="linear-gradient(to bottom,#e7eaf0,#d9dce1)"
              borderColor="#ADB1B8 #A2A6AC #8D9096"
              borderColorHover="#a2a6ac #979aa1 #82858a"
              borderColorFocus="#e77600"
              borderRadius="3px"
              border="1px solid"
              copy={permissionsInfoData?.permissionButtonData?.buttonText ?? 'Manage Permissions'}
              margin="8px 0px 0px"
              boxShadowFocus="0 0 3px 2px rgb(228 121 17 / 50%)"
              onClick={() => {
                handlePermissionModalOpen();
              }}
            />
          </>
        ) : (
          <AlexaButton
            aria-label={permissionsInfoData?.permissionsSavingSettings ?? 'Saving Settings'}
            copy={permissionsInfoData?.permissionsSavingSettings ?? 'Saving Settings...'}
            fontSize="16px"
            lineHeight="1.35"
            padding="12px 16px 12px 16px"
            height="auto"
            color="#111"
            background="#dddddd !important"
            borderColor="#bababa !important"
            borderRadius="3px"
            border="1px solid"
            margin="8px 0px 0px"
            disabled={true}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={() => {}}
          />
        ))}
    </CanvasContainer>
  ) : null;
};

export * from './types';
