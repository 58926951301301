import { StoreActionApi } from 'react-sweet-state';

import { BuyBoxState } from '../../types';

const init = (state: BuyBoxState) => ({ setState }: StoreActionApi<BuyBoxState>) => {
  if (state) {
    setState(state);
  }
};

export default init;
