import React from 'react';

import * as AlexaSharedComponents from '@amzn/monera-app-alexa-shared-libs/lib/components';
import * as CoreComponents from '@amzn/monera-app-core-components/lib/components';

import {
  AlexaSkillPermissions,
  AlexaStoresContainer,
  FreeSkillAlertBannerGroup,
  FreeSkillBuyBox,
  FreeSkillLogo,
  FreeToEnable,
  MShopDownloadAlexaApp,
  Privacy,
  ProductDescription,
  SkillDetails
} from '../../components';

export const componentsById: Record<string, React.ComponentType<any>> = {
  ////////////////////////////
  // @amzn/monera-app-alexa //
  ////////////////////////////
  'free-skill-buy-box': FreeSkillBuyBox,
  'skill-product-image': AlexaSharedComponents.ProductImage,
  'alexa-free-skill-logo': FreeSkillLogo,
  'skill-product-description': ProductDescription,
  'skill-details': SkillDetails,
  'privacy': AlexaSharedComponents.Privacy,
  'free-skill-alert-banner-group': FreeSkillAlertBannerGroup,
  'free-skill-supported-languages': AlexaSharedComponents.SupportedLanguages,
  'free-to-enable': FreeToEnable,
  'free-skill-highlights': AlexaSharedComponents.SkillHighlights,
  'in-skill-purchases-available': AlexaSharedComponents.InSkillPurchasesAvailable,
  'free-skill-title': AlexaSharedComponents.SkillTitle,
  'free-skill-publisher': AlexaSharedComponents.SkillPublisher,
  'free-skill-maturity-rating': AlexaSharedComponents.MaturityRating,
  'about-this-item': AlexaSharedComponents.AboutThisItem,
  'alexa-skill-share': AlexaSharedComponents.SkillShare,
  'utterance-bubbles-desktop': AlexaSharedComponents.UtteranceBubblesDesktop,
  'utterance-bubbles-mobile': AlexaSharedComponents.UtteranceBubblesMobile,
  'in-skill-purchases': AlexaSharedComponents.InSkillPurchases,
  'bundles': AlexaSharedComponents.Bundles,
  'free-skill-skill-permissions': AlexaSkillPermissions,
  'mshop-download-alexa-app': MShopDownloadAlexaApp,
  'alexa-free-skill-meta-tag': CoreComponents.MetaTag,
  'free-skill-note': AlexaSharedComponents.SkillNote,
  'store-container': AlexaStoresContainer,
  ////////////////////////////
  // @amzn/monera-app-core //
  ////////////////////////////
  breadcrumbs: CoreComponents.Breadcrumbs,
  hero: CoreComponents.Hero,
  'average-customer-reviews': CoreComponents.CustomerReviews,
  'html-widget': CoreComponents.HtmlWidget,
  'canvas-container': CoreComponents.CanvasContainer,
  'canvas-item': CoreComponents.CanvasItem,
  'customer-reviews-medley': CoreComponents.HtmlWidget,
  divider: CoreComponents.Divider,
  'hero-banner': CoreComponents.Media,
  'atf-marker': CoreComponents.AtfMarker
};
