import React, { FunctionComponent } from 'react';
import ReactHtmlParser from 'html-react-parser';

import {
  SkillSpecificMessageBox,
  SkillSpecificMessageBoxInner,
  SkillSpecificMessageBoxLink,
  SkillSpecificMessageBoxText
} from './styles';
import { SkillSpecificMessageProps } from './types';

export const SkillSpecificMessage: FunctionComponent<SkillSpecificMessageProps> = (props) => {
  const { learnMoreLink, learnMoreText, message } = props;
  return (
    <SkillSpecificMessageBox>
      <SkillSpecificMessageBoxInner>
        <SkillSpecificMessageBoxText>
          {ReactHtmlParser(message)}
          {learnMoreLink && learnMoreText && 
            (<SkillSpecificMessageBoxLink href={learnMoreLink} target="_blank">
              {' '}
              {learnMoreText}
            </SkillSpecificMessageBoxLink>) }
        </SkillSpecificMessageBoxText>
      </SkillSpecificMessageBoxInner>
    </SkillSpecificMessageBox>
  );
};
