import styled from 'styled-components';

export const InfoIcon = styled.i`
  position: absolute;
  height: 28px;
  width: 26px;
  background-position: -350px -35px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://m.media-amazon.com/images/S/sash/McBZv0ZvnbehkIx.png);
  background-size: 400px 900px;
  background-repeat: no-repeat;
`;

export const DrsMessageDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
`;
