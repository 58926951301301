import React, { FunctionComponent, useState } from 'react';

import {
  AlexaModal,
  AlexaModalProps,
  AlexaSwitch,
  AlexaSwitchProps,
  AlexaText
} from '@amzn/monera-app-alexa-shared-libs';
import { serverConsole, useConfigStore, useShoppingPortalStore } from '@amzn/monera-app-core';

import { enableSkill, setKSTStatus } from '../../services';
import { KSTItemContainer, KSTItemTextWrapper, KSTItemWrapper, KSTLearnMoreLink } from './styles';
import { AlexaKSTModalProps, KSTModalBodyProps } from './types';

// TODO: disable button until KST status switch is set to
export const AlexaKSTModal: FunctionComponent<AlexaKSTModalProps> = (props) => {
  const { kstHeaderTitle, kstModalBody, okButtonText, closeButtonText, modalProps } = props;
  const [configRegistryState] = useConfigStore();
  const [shoppingPortalRegistryState] = useShoppingPortalStore();
  const [isOn, setIsOn] = useState<boolean>(kstModalBody.kstEnabled);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const handleSave = async () => {
    const kstSet = await setKSTStatus({
      requestId: configRegistryState.requestId,
      asin: configRegistryState.asin,
      tenant: configRegistryState.tenant ?? '',
      marketplaceId: shoppingPortalRegistryState.marketplaceId,
      sessionId: shoppingPortalRegistryState.sessionId,
      ubid: shoppingPortalRegistryState.ubid,
      csrfToken: configRegistryState.csrfToken,
      customerId: shoppingPortalRegistryState.customerId,
      status: 'true'
    });
    if (kstSet) {
      const enabled = await enableSkill({
        requestId: configRegistryState.requestId,
        asin: configRegistryState.asin,
        tenant: configRegistryState.tenant ?? '',
        marketplaceId: shoppingPortalRegistryState.marketplaceId,
        sessionId: shoppingPortalRegistryState.sessionId,
        ubid: shoppingPortalRegistryState.ubid,
        csrfToken: configRegistryState.csrfToken,
        customerId: shoppingPortalRegistryState.customerId
      });
      if (enabled) {
        serverConsole.log('KST set and skill enabled');
      } else {
        serverConsole.log('enable failed');
      }
      window.location.href = `${window.location.href}`;
    } else {
      serverConsole.log('KST set failed skill not enabled');
    }
  };

  const getModalBodyContent = () => {
    return <KSTModalBody {...kstModalBody} isOn={isOn} onToggleAction={handleToggle} />;
  };

  const alexaModalProps: AlexaModalProps = {
    modalTitleCopy: kstHeaderTitle,
    modalBodyContent: getModalBodyContent(),
    acceptButtonCopy: okButtonText,
    closeButtonCopy: closeButtonText,
    onAcceptAction: handleSave,
    disableAcceptButton: !isOn,
    modalProps
  };

  return <AlexaModal {...alexaModalProps} />;
};

export const KSTModalBody: FunctionComponent<KSTModalBodyProps> = (props) => {
  const { kstName, kstDescription, kstLearnMoreText, kstLearnMoreURL, onToggleAction, isOn } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const switchProps: AlexaSwitchProps = {
    name: kstName,
    isHovered,
    toggle: onToggleAction,
    ...props
  };
  return (
    <KSTItemContainer onMouseOver={handleMouseEnter} onMouseOut={handleMouseLeave}>
      <KSTItemWrapper>
        <KSTItemTextWrapper>
          <AlexaText
            bold
            copy={kstName}
            color={isOn ? '#c45500' : '#767676'}
            transition="all .3s ease"
            lineHeight="21px"
            margin="0px 6px 0px 0px"
            padding="0px 0px 2px 0px"
          />
          <AlexaText color="#565959" fontSize="12px" lineHeight="1.5" copy={kstDescription} />
          <KSTLearnMoreLink href={kstLearnMoreURL} target={'_blank'}>
            {kstLearnMoreText}
          </KSTLearnMoreLink>
        </KSTItemTextWrapper>
      </KSTItemWrapper>
      <AlexaSwitch {...switchProps}></AlexaSwitch>
    </KSTItemContainer>
  );
};

export * from './types';
