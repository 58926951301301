import styled from 'styled-components';

import { alexaTheme } from '../../theme';

export const SkillSpecificMessageBox = styled.div`
  border: 0;
  text-align: left;
  margin-bottom: 12px;
  background-color: #f3f3f3;
  display: block;
  border-radius: 4px;
`;

export const  SkillSpecificMessageBoxInner = styled.div`
  border-radius: 4px;
  position: relative;
  padding: 6px 10px;
`;

export const  SkillSpecificMessageBoxText = styled.span`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Amazon Ember', Arial, sans-serif;
  font-size: ${alexaTheme.typography?.desktopSM?.fontSize};
  line-height: ${alexaTheme.typography?.desktopSM?.lineHeight};
`;

export const  SkillSpecificMessageBoxLink = styled.a`
  font-family: verdana, arial, helvetica, sans-serif;
  text-decoration: none;
  color: #0066c0;
`;
