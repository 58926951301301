import styled from 'styled-components';

const GRAY = '#a2a6ac';

export const MShopDownloadAlexaAppButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid ${GRAY};
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  height: 48px;
  padding: 0px 10px 0px 0px;
  font-family: 'Amazon Ember', Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
