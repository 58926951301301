import React, { FunctionComponent } from 'react';

import { alexaTypography } from '../../theme';
import { AlexaText } from '@amzn/monera-app-alexa-shared-libs';
import { FreeToEnableProps } from './types';

export const FreeToEnable: FunctionComponent<FreeToEnableProps> = (props) => {
  const { copy, isMobile = false, textProps = { margin: '8px 0px 8px 0px', color: '#B12704' } } = props;

  const defaultTextProps = isMobile ? alexaTypography.mobileMD : alexaTypography.desktopMD;

  return (
    <AlexaText {...defaultTextProps} {...textProps} data-cy={'free-to-enable'}>
      {copy}
    </AlexaText>
  );
};

export * from './types';
